// C9 CONSTANTS
export const TARGETS_DATA = "TARGETS_DATA";
export const CURRENT_TARGET = "CURRENT_TARGET";
export const CURRENT_USER = "CURRENT_USER";
export const PALETTES = "PALETTES"


// SETTINGS CONSTANTS
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";

// TODO CONSTANTS
export const FETCH_TODO_REQUEST = "FETCH_TODO_REQUEST";
export const FETCH_TODO_SUCCESS = "FETCH_TODO_SUCCESS";
export const FETCH_TODO_FAILURE = "FETCH_TODO_FAILURE";
export const TOGGLE_STARRED_TODO = "TOGGLE_STARRED_TODO";
export const SET_VISIBILITY_FILTER_TODO = "SET_VISIBILITY_FILTER_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const TODO_DETAILS = "TODO_DETAILS";
export const FILTER_TODO = "FILTER_TODO";
export const COMPLETE_TODO = "COMPLETE_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const ADD_TODO = "ADD_TODO";

// CONTACT CONSTANTS
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const FILTER_CONTACT = "FILTER_CONTACT";
export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// EMAIL CONSTANTS
export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

// NOTES CONSTANTS

export const SELECTED_NOTES = "SELECTED_NOTES";
export const SEARCH_NOTES = "SEARCH_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";


export const ActionTypeList = {
    /********************* User Setting ****************/
    USER_SETTING_FETCH_LIST: 'USER_SETTING_FETCH_LIST',
    USER_SETTING_LIST_FETCHED: 'USER_SETTING_LIST_FETCHED',

    USER_SETTING_UPDATE_EDIT_MODE: 'USER_SETTING_UPDATE_EDIT_MODE',
    USER_SETTING_EDIT_MODE_UPDATED: 'USER_SETTING_EDIT_MODE_UPDATED',

    USER_SETTING_TOGGLE_NEW_FLAG: 'USER_SETTING_TOGGLE_NEW_FLAG',
    USER_SETTING_NEW_FLAG_TOGGLED: 'USER_SETTING_NEW_FLAG_TOGGLED',

    USER_SETTING_FETCH_DETAIL: 'USER_SETTING_FETCH_DETAIL',
    USER_SETTING_DETAIL_FETCHED: 'USER_SETTING_DETAIL_FETCHED',

    USER_SETTING_NEW_DETAIL: 'USER_SETTING_NEW_DETAIL',
    USER_SETTING_DETAIL_NEWED: 'USER_SETTING_DETAIL_NEWED',

    USER_SETTING_UPDATE: 'USER_SETTING_UPDATE',
    USER_SETTING_UPDATED: 'USER_SETTING_UPDATED',
    USER_SETTING_UPDATED_HIDE_MSG: 'USER_SETTING_UPDATED_HIDE_MSG',
    USER_SETTING_INSERT: 'USER_SETTING_INSERT',
    USER_SETTING_INSERTED: 'USER_SETTING_INSERTED',
    USER_SETTING_INSERTED_HIDE_MSG: 'USER_SETTING_INSERTED_HIDE_MSG',
    USER_SETTING_DELETE: 'USER_SETTING_DELETE',
    USER_SETTING_DELETED: 'USER_SETTING_DELETED',
    USER_SETTING_DELETED_HIDE_MSG: 'USER_SETTING_DELETED_HIDE_MSG',
    USER_SETTING_UPLOAD_PROFILE_PICTURE: 'USER_SETTING_UPLOAD_PROFILE_PICTURE',
    USER_SETTING_PROFILE_PICTURE_UPLOADED: 'USER_SETTING_PROFILE_PICTURE_UPLOADED',

    USER_SETTING_UPDATE_FIELD: 'USER_SETTING_UPDATE_FIELD',
    USER_SETTING_FIELD_UPDATED: 'USER_SETTING_FIELD_UPDATED',

    USER_SETTING_UPDATE_CURRENT_USER_ID: 'USER_SETTING_UPDATE_CURRENT_USER_ID',
    USER_SETTING_CURRENT_USER_ID_UPDATED: 'USER_SETTING_CURRENT_USER_ID_UPDATED',

    USER_SETTING_UPDATE_DETAIL_PAGE_MODE: 'USER_SETTING_UPDATE_DETAIL_PAGE_MODE',
    USER_SETTING_DETAIL_PAGE_MODE_UPDATED: 'USER_SETTING_DETAIL_PAGE_MODE_UPDATED',

    USER_SETTING_LOAD_RESET_PASSWORD_PAGE: 'USER_SETTING_LOAD_RESET_PASSWORD_PAGE',
    USER_SETTING_RESET_PASSWORD_PAGE_LOADED: 'USER_SETTING_RESET_PASSWORD_PAGE_LOADED',
    USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE: 'USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE',
    USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED: 'USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED',

    /********************* Target Setting ****************/
    TARGET_SETTING_FETCH_LIST: 'TARGET_SETTING_FETCH_LIST',
    TARGET_SETTING_LIST_FETCHED: 'TARGET_SETTING_LIST_FETCHED',

    TARGET_SETTING_UPDATE_EDIT_MODE: 'TARGET_SETTING_UPDATE_EDIT_MODE',
    TARGET_SETTING_EDIT_MODE_UPDATED: 'TARGET_SETTING_EDIT_MODE_UPDATED',

    TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE: 'TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE',
    TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED: 'TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED',

    TARGET_SETTING_TOGGLE_NEW_FLAG: 'TARGET_SETTING_TOGGLE_NEW_FLAG',
    TARGET_SETTING_NEW_FLAG_TOGGLED: 'TARGET_SETTING_NEW_FLAG_TOGGLED',

    TARGET_SETTING_FETCH_DETAIL: 'TARGET_SETTING_FETCH_DETAIL',
    TARGET_SETTING_DETAIL_FETCHED: 'TARGET_SETTING_DETAIL_FETCHED',

    TARGET_SETTING_NEW_DETAIL: 'TARGET_SETTING_NEW_DETAIL',
    TARGET_SETTING_DETAIL_NEWED: 'TARGET_SETTING_DETAIL_NEWED',

    TARGET_SETTING_UPDATE: 'TARGET_SETTING_UPDATE',
    TARGET_SETTING_UPDATED: 'TARGET_SETTING_UPDATED',
    TARGET_SETTING_INSERT: 'TARGET_SETTING_INSERT',
    TARGET_SETTING_INSERTED: 'TARGET_SETTING_INSERTED',
    TARGET_SETTING_DELETE: 'TARGET_SETTING_DELETE',
    TARGET_SETTING_DELETED: 'TARGET_SETTING_DELETED',
    TARGET_SETTING_INSERTED_HIDE_MSG: 'TARGET_SETTING_INSERTED_HIDE_MSG',

    TARGET_SETTING_UPDATE_FIELD: 'TARGET_SETTING_UPDATE_FIELD',
    TARGET_SETTING_FIELD_UPDATED: 'TARGET_SETTING_FIELD_UPDATED',

    TARGET_SETTING_UPDATE_CURRENT_TARGET_ID: 'TARGET_SETTING_UPDATE_CURRENT_TARGET_ID',
    TARGET_SETTING_CURRENT_TARGET_ID_UPDATED: 'TARGET_SETTING_CURRENT_TARGET_ID_UPDATED',

    TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: 'TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE',
    TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED: 'TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED',

    /********************* Dashboard ****************/
    DASHBOARD_DATA_FETCH: 'DASHBOARD_DATA_FETCH',
    DASHBOARD_DATA_FETCHED: 'DASHBOARD_DATA_FETCHED',
    
    /********************* Scannings ****************/
    SCANNINGS_DATA_FETCH: 'SCANNINGS_DATA_FETCH',
    SCANNINGS_DATA_FETCHED: 'SCANNINGS_DATA_FETCHED',
    
    /********************* Findings ****************/
    FINDINGS_DATA_FETCH: 'FINDINGS_DATA_FETCH',
    FINDINGS_DATA_FETCHED: 'FINDINGS_DATA_FETCHED',
    WIKI_DATA_FETCH: 'WIKI_DATA_FETCH',
    WIKI_DATA_FETCHED: 'WIKI_DATA_FETCHED',
    
    /********************* Target details ****************/
    TARGET_DETAILS_DATA_FETCH: 'TARGET_DETAILS_DATA_FETCH',
    TARGET_DETAILS_DATA_FETCHED: 'TARGET_DETAILS_DATA_FETCHED',

    /********************* Settings ****************/
    SETTINGS_DATA_FETCH: 'SETTINGS_DATA_FETCH',
    SETTINGS_DATA_FETCHED: 'SETTINGS_DATA_FETCHED',

    /********************* Authentication ****************/
    AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER: 'AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER',
    AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED: 'AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED',

    LOGIN_USER_GET: 'LOGIN_USER_GET',
    LOGIN_USER_GOTTEN: 'LOGIN_USER_GOTTEN',
    LOGIN_USER_UPDATE_FIELD: 'LOGIN_USER_UPDATE_FIELD',
    LOGIN_USER_FIELD_UPDATED: 'LOGIN_USER_FIELD_UPDATED',
    LOGIN_USER_UPDATE: 'LOGIN_USER_UPDATE',
    LOGIN_USER_UPDATED: 'LOGIN_USER_UPDATED',
    LOGIN_USER_UPDATE_EDIT_MODE: 'USER_SETTING_UPDATE_EDIT_MODE',
    LOGIN_USER_EDIT_MODE_UPDATED: 'USER_SETTING_EDIT_MODE_UPDATED',
}

export const ActionTypeEventMappingList = {
    /********************* User Setting ****************/
    USER_SETTING_FETCH_LIST: ActionTypeList.USER_SETTING_LIST_FETCHED,
    USER_SETTING_UPDATE: ActionTypeList.USER_SETTING_UPDATED,
    USER_SETTING_INSERT: ActionTypeList.USER_SETTING_INSERTED,
    USER_SETTING_DELETE: ActionTypeList.USER_SETTING_DELETED,
    USER_SETTING_UPDATE_EDIT_MODE: ActionTypeList.USER_SETTING_EDIT_MODE_UPDATED,
    USER_SETTING_FETCH_DETAIL: ActionTypeList.USER_SETTING_DETAIL_FETCHED,
    USER_SETTING_TOGGLE_NEW_FLAG: ActionTypeList.USER_SETTING_NEW_FLAG_TOGGLED,
    USER_SETTING_UPDATE_FIELD: ActionTypeList.USER_SETTING_FIELD_UPDATED,
    USER_SETTING_UPDATE_CURRENT_USER_ID: ActionTypeList.USER_SETTING_CURRENT_USER_ID_UPDATED,
    USER_SETTING_UPDATE_DETAIL_PAGE_MODE: ActionTypeList.USER_SETTING_DETAIL_PAGE_MODE_UPDATED,
    USER_SETTING_UPLOAD_PROFILE_PICTURE: ActionTypeList.USER_SETTING_PROFILE_PICTURE_UPLOADED,
    USER_SETTING_LOAD_RESET_PASSWORD_PAGE: ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_LOADED,
    USER_SETTING_UNLOAD_RESET_PASSWORD_PAGE: ActionTypeList.USER_SETTING_RESET_PASSWORD_PAGE_UNLOADED,

    /********************* Target Setting ****************/
    TARGET_SETTING_FETCH_LIST: ActionTypeList.TARGET_SETTING_LIST_FETCHED,
    TARGET_SETTING_UPDATE: ActionTypeList.TARGET_SETTING_UPDATED,
    TARGET_SETTING_INSERT: ActionTypeList.TARGET_SETTING_INSERTED,
    TARGET_SETTING_DELETE: ActionTypeList.TARGET_SETTING_DELETED,
    TARGET_SETTING_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_EDIT_MODE_UPDATED,
    TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE: ActionTypeList.TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED,
    TARGET_SETTING_FETCH_DETAIL: ActionTypeList.TARGET_SETTING_DETAIL_FETCHED,
    TARGET_SETTING_TOGGLE_NEW_FLAG: ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED,
    TARGET_SETTING_UPDATE_FIELD: ActionTypeList.TARGET_SETTING_FIELD_UPDATED,
    TARGET_SETTING_UPDATE_CURRENT_TARGET_ID: ActionTypeList.TARGET_SETTING_CURRENT_TARGET_ID_UPDATED,
    TARGET_SETTING_UPDATE_DETAIL_PAGE_MODE: ActionTypeList.TARGET_SETTING_DETAIL_PAGE_MODE_UPDATED,

    /********************* Dashboard ****************/
    DASHBOARD_DATA_FETCH: ActionTypeList.DASHBOARD_DATA_FETCHED,
    
    /********************* Scannings ****************/
    SCANNINGS_DATA_FETCH: ActionTypeList.SCANNINGS_DATA_FETCHED,
    
    /********************* Findings ****************/
    FINDINGS_DATA_FETCH: ActionTypeList.FINDINGS_DATA_FETCHED,
    WIKI_DATA_FETCH: ActionTypeList.WIKI_DATA_FETCHED,
    
    /********************* Target details ****************/
    TARGET_DETAILS_DATA_FETCH: ActionTypeList.TARGET_DETAILS_DATA_FETCHED,

    /********************* Settings ****************/
    SETTINGS_DATA_FETCH: ActionTypeList.SETTINGS_DATA_FETCHED,

    /********************* Authentication ****************/
    AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER: ActionTypeList.AUTHENTICATION_CURRENT_LOGIN_USER_UPDATED,

    LOGIN_USER_GET: ActionTypeList.LOGIN_USER_GOTTEN,

    LOGIN_USER_UPDATE_FIELD: ActionTypeList.LOGIN_USER_FIELD_UPDATED,
    LOGIN_USER_UPDATE: ActionTypeList.LOGIN_USER_UPDATED,
    LOGIN_USER_UPDATE_EDIT_MODE: ActionTypeList.LOGIN_USER_EDIT_MODE_UPDATED,
}
