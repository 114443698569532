import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/style.scss";
import './assets/scss/style.css';
import "./assets/scss/syncfusion.css";
import "./assets/scss/cloud9security.css";
import Spinner from "./views/spinner/Spinner";
import { configureStore } from "./redux/Store";

// setup fake backend
import { ConfigureFakeBackend } from "./authentication/_helpers";

ConfigureFakeBackend();

const App = lazy(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import("./app")), 0);
        })
);

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <Provider store={configureStore()}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById("root")
);
