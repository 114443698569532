import { ActionTypeList } from '../../constants'

const initialState = false;

const newTargetFlagPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED:
        case ActionTypeList.TARGET_SETTING_INSERTED:
            return true;
        case ActionTypeList.TARGET_SETTING_TOGGLE_NEW_FLAG:
            return state;
        case ActionTypeList.TARGET_SETTING_CURRENT_TARGET_ID_UPDATED:
            return false;
        default:
            return state;
    }
}

export default newTargetFlagPayload;