import { ActionTypeList } from '../constants'

const initialState = {
    loading: false,
    findingsData: null,
    error: "",
};

const findingsDataPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.FINDINGS_DATA_FETCHED:
            return {
                loading: false,
                findingsData: action.payload,
                error: ""
            };
        case ActionTypeList.FINDINGS_DATA_FETCH:
            return {
                loading: true,
                findingsData: null,
                error: action.payload
            };
        default:
            return state;
    }
}

export default findingsDataPayload;