import { AuthenticationService } from "../_services";
import { History } from './History';

export function HandleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                AuthenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function HandleJsonResponse(response) {
    console.log('HandleJsonResponse, response: ' + JSON.stringify(response));

    const data = response.data;

    if (data.status !== 200) {

        const error = data.statusText;

        console.log('HandleJsonResponse, throw error: ' + JSON.stringify(error));

        throw error;
    }

    return data.data;
}

export function HandleGetJsonResponseWithAthenticationCheck(response) {

    const data = response.data;

    if (data.status !== 200) {
        if ([401, 403].indexOf(data.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //console.log('AuthenticationService.logout() caused by 401/403');

            AuthenticationService.logout();

            //updateCurrentLoginUser(null);

            //console.log('history.push(authentication/login)');

            History.push("/authentication/login");
            //window.location.reload(true);
        }

        const error = data.statusText;

        //console.log('HandleJsonResponse, throw error: ' + JSON.stringify(error));

        throw error;
    }

    return data.data;
}

export function performAthenticationCheck(response) {

    const data = response.data;

    if (data.status !== 200) {
        if ([401, 403].indexOf(data.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //console.log('AuthenticationService.logout() caused by 401/403');

            AuthenticationService.logout();

            //updateCurrentLoginUser(null);

            //console.log('history.push(authentication/login)');

            History.push("/authentication/login");
            //window.location.reload(true);
        }

        const error = data.statusText;

        //console.log('HandleJsonResponse, throw error: ' + JSON.stringify(error));

        throw error;
    }
}
