import { BehaviorSubject } from "rxjs";

import { HandleJsonResponse, HandleResponse } from "../_helpers";
import axios from 'axios';

const currentAxios = axios.create({ baseURL: "https://app-staging.cloud9pentest.com/apis" })

const currentUserSubject = new BehaviorSubject(
    JSON.parse(sessionStorage.getItem("currentUser"))
);

export const AuthenticationService = {
    login,
    realLogin,
    logout,
    requestPasswordReset,
    resetPassword,
    activateAccount,
    changePassword,
    adminResetPassword,
    unlock,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
};

function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(HandleResponse)
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

async function realLogin(username, password) {
    const body = { username, password };

    return await currentAxios.post(`/security/basic-authenticate`, body).
        then(HandleJsonResponse).
        then((user) => {
            //console.log('After HandleJsonResponse, User: ' + JSON.stringify(user));
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user.data));
            currentUserSubject.next(user);

            return user;
        });
}

async function unlock(accountId, userId, password) {

    const body = { password };

    return await currentAxios.post(`/accounts/${accountId}/users/${userId}/password-validation`, body).
        then(HandleJsonResponse);
}

async function requestPasswordReset(userEmail) {
    //console.log("AuthenticationService.requestPasswordReset starts");

    const body = { userEmail: userEmail };

    return await currentAxios.post(`/password-reset`, body).
        then(HandleJsonResponse);
}

async function resetPassword(id, newPassword) {
    //console.log("AuthenticationService.resetPassword starts");

    const body = { newPassword };

    return await currentAxios.put(`/password-reset/${id}`, body).
        then(HandleJsonResponse);
}

async function activateAccount(id, newPassword) {
    //console.log("AuthenticationService.activateAccount starts");

    const body = { newPassword };

    return await currentAxios.put(`/member-activation/${id}`, body).
        then(HandleJsonResponse);
}

async function changePassword(accountId, userId, currentPassword, newPassword) {
    //console.log("AuthenticationService.changePassword starts");

    const body = { currentPassword, newPassword };

    return await currentAxios.put(`/accounts/${accountId}/users/${userId}/password`, body).
        then(HandleJsonResponse);
}

async function adminResetPassword(accountId, userId, newPassword) {
    //console.log("AuthenticationService.adminResetPassword starts");

    const body = { newPassword };

    return await currentAxios.put(`/accounts/${accountId}/users/${userId}/password`, body).
        then(HandleJsonResponse);
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem("currentUser");
    currentUserSubject.next(null);
}
