import { ActionTypeList } from '../../constants'

const initialState = false;

const editTargetScanConfigFlagPayload = (state = initialState, action) => {
    //console.log(`editTargetScanConfigFlagPayload state ${JSON.stringify(state)} action ${JSON.stringify(action)}`);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_SCAN_CONFIG_EDIT_MODE_UPDATED:
            return action.payload;
        case ActionTypeList.TARGET_SETTING_INSERTED:
            return !state;
        case ActionTypeList.TARGET_SETTING_UPDATED:
            return false;
        case ActionTypeList.TARGET_SETTING_SCAN_CONFIG_UPDATE_EDIT_MODE:
            return state;
        default:
            return state;
    }
}

export default editTargetScanConfigFlagPayload;