import { combineReducers } from "redux";
import settings from "./settings/Reducer";
import usersPayload from "./user-setting/fetch-list/Reducer";
import currentUserDetailPayload from "./user-setting/fetch-detail/Reducer";
import userUpdateStatusPayload from "./user-setting/update-detail/Reducer";
import userInsertStatusPayload from "./user-setting/insert-detail/Reducer";
import userDeleteStatusPayload from "./user-setting/delete-detail/Reducer";
import currentLoginUserPayload from "./current-login-user/Reducer";
import editUserFlagPayload from "./user-setting/edit-flag/Reducer";
import newUserFlagPayload from "./user-setting/new-flag/Reducer";
import currentUserIdPayload from "./user-setting/update-current-user-id/Reducer";
import resetPasswordPageLoadStatusPayload from "./user-setting/load-password-reset-page/Reducer";
import detailPageModePayload from "./user-setting/detail-page-mode/Reducer";
import fetchDashboardDataPayload from "./dashboard/Reducer";
import fetchScanningsDataPayload from "./scannings/Reducer";
import fetchTargetDetailsDataPayload from "./target-details/Reducer";
import fetchSettingsDataPayload from "./settings-page/Reducer";
import fetchFindingsDataPayload from "./findings/Reducer";
import fetchWikiDataPayload from "./wiki/Reducer";
import targetDetailPageModePayload from "./target-setting/detail-page-mode/Reducer";
import newTargetFlagPayload from "./target-setting/new-flag/Reducer";
import targetInsertStatusPayload from "./target-setting/insert-detail/Reducer";
import currentTargetSettingPayload from "./target-setting/fetch-detail/Reducer";
import targetSettingsPayload from "./target-setting/fetch-list/Reducer";
import targetSettingUpdateStatusPayload from "./target-setting/update/Reducer";
import targetSettingDeleteStatusPayload from "./target-setting/delete/Reducer";
import editTargetSettingFlagPayload from "./target-setting/edit-flag/Reducer";
import editTargetScanConfigFlagPayload from "./target-setting/edit-scan-config-flag/Reducer";

export default combineReducers({
    settings,
    usersPayload,
    userInsertStatusPayload,
    userUpdateStatusPayload,
    userDeleteStatusPayload,
    editUserFlagPayload,
    fetchDashboardDataPayload,
    newUserFlagPayload,
    currentUserDetailPayload,
    currentUserIdPayload,
    resetPasswordPageLoadStatusPayload,
    detailPageModePayload,
    fetchWikiDataPayload,
    fetchScanningsDataPayload,
    fetchTargetDetailsDataPayload,
    fetchSettingsDataPayload,
    fetchFindingsDataPayload,
    currentLoginUserPayload,
    targetInsertStatusPayload,
    newTargetFlagPayload,
    targetDetailPageModePayload,
    currentTargetSettingPayload,
    targetSettingsPayload,
    targetSettingUpdateStatusPayload,
    targetSettingDeleteStatusPayload,
    editTargetSettingFlagPayload,
    editTargetScanConfigFlagPayload
});