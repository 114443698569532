import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    targetSettings: [],
    error: "",
};

const targetSettingsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_LIST_FETCHED:
            return {
                loading: false,
                targetSettings: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_FETCH_LIST:
            return {
                loading: true,
                targetSettings: [],
                error: action.payload
            };
        default:
            return state;
    }
}

export default targetSettingsPayload;